/* zip.js */
var zip = document.querySelector('.is-custom-gf-zip input');
var city = document.querySelector('.is-custom-gf-city input');
var province = document.querySelector('.is-custom-gf-province input');
var street = document.querySelector('.is-custom-gf-street input');

window.checkZip = function (e) {
  var value = e.target.value;
  var url = window.rest;
  fetch("".concat(rest.restUrl + 'inc/zip.php?json=' + value)).then(function (res) {
    return res.json();
  }).then(function (res) {
    console.log(res);
    city.value = res['city'];
    province.value = res['province'];
    street.value = res['street'];
  })["catch"](function (error) {
    return console.error(error);
  });
};

if (null != zip) {
  zip.addEventListener('blur', checkZip);
}