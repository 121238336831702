import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/* tools.js */
// Calculate: loan
var loan = document.querySelector('.acf-loan-block');

var calculateLoan = function calculateLoan() {
  var form = document.forms.loan;
  var formData = new FormData(form);
  var data = new URLSearchParams(formData).toString();
  console.log(data);
  fetch("".concat(rest.restUrl + 'calc/v1/loan?' + data + '&_wpnonce=' + rest.restNonce)).then(function (res) {
    return res.json();
  }).then(function (res) {
    console.log(res);

    var _iterator = _createForOfIteratorHelper(Object.entries(res).reverse()),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 2),
            key = _step$value[0],
            value = _step$value[1];

        loan.querySelector('.name .value').innerHTML = "".concat(value.name);
        loan.querySelector('.interest .value').innerHTML = "".concat(value.interest, "%");
        loan.querySelector('.installment .value').innerHTML = "\u20AC ".concat(value.installment);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  })["catch"](function (error) {
    return console.error(error);
  });
};

if (null != loan) {
  var amount = loan.querySelector('[name="loan_amount"]');
  var duration = loan.querySelector('[name="loan_duration"]');
  amount.addEventListener('change', function (e) {
    try {
      checkMinMax(e.target);
      calculateLoan();
    } catch (e) {
      console.log('Error.');
      return false;
    }
  });
  duration.addEventListener('change', function (e) {
    try {
      calculateLoan();
    } catch (e) {
      console.log('Error.');
      return false;
    }
  });
} // Calculate: max


var maxLoan = document.querySelector('.acf-max-loan-block');

var calculateMaxLoan = function calculateMaxLoan() {
  var form = document.forms.max_loan;
  var formData = new FormData(form);
  var data = new URLSearchParams(formData).toString();
  fetch("".concat(rest.restUrl + 'calc/v1/max-loan?' + data + '&_wpnonce=' + rest.restNonce)).then(function (res) {
    return res.json();
  }).then(function (res) {
    log(res);
    maxLoan.querySelector('.result .notice').innerHTML = res.notice;
  })["catch"](function (error) {
    return console.error(error);
  });
};

if (null != maxLoan) {
  var text = maxLoan.querySelectorAll('input[type="text"]');
  var select = maxLoan.querySelectorAll('select');
  var partner = maxLoan.querySelector('.burgerlijke-staat select');

  for (var i = 0; i < text.length; i++) {
    text[i].addEventListener('change', function (e) {
      try {
        calculateMaxLoan();
      } catch (e) {
        console.log('Error.');
        return false;
      }
    });
  }

  for (var _i = 0; _i < select.length; _i++) {
    select[_i].addEventListener('change', function (e) {
      try {
        calculateMaxLoan();
      } catch (e) {
        console.log('Error.');
        return false;
      }
    });
  }

  partner.addEventListener('change', function (e) {
    if (e.target.value != 2) {
      maxLoan.querySelector('.inkomen-partner').classList.remove('hidden');
    } else {
      maxLoan.querySelector('.inkomen-partner').classList.add('hidden');
    }
  });
} // Calculate: save


var saveLoan = document.querySelector('.acf-save-loan-block');

var removeLoan = function removeLoan(e) {
  e.preventDefault();
  e.target.closest('.loan').remove();
};

var addLoan = function addLoan(e) {
  var loans = saveLoan.querySelector('.loans');
  loans.dataset.loans++;
  var count = loans.dataset.loans;
  console.log(count);
  var newLoan = document.createElement('div');
  newLoan.classList.add('loan');
  newLoan.dataset.loan = count++;
  newLoan.innerHTML = "\n\t\t<div class=\"flex relative items-center gap-2 mb-2 md:mb-4\">\n\t\t\t<span class=\"font-700\">Lening:</span>\n\t\t\t<span class=\"remove-loan\"><svg xmlns=\"http://www.w3.org/2000/svg\" class=\"w-[20px] h-[20px] fill-error cursor-pointer\" viewBox=\"0 0 512 512\"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d=\"M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z\"/></svg></span>\n\t\t</div>\n\t\t\n\t\t<div class=\"grid relative md:grid-flow-col md:auto-cols-fr gap-4\">\n\t\t\t\n\t\t\t<div class=\"relative flex-1\">\n\t\t\t\t<label for=\"lening-".concat(count, "-saldo\">Saldo</label>\n\t\t\t\t<input type=\"number\" id=\"lening-").concat(count, "-saldo\" min=\"5000\" max=\"150000\" name=\"saldo[]\" placeholder=\"&euro;\" value=\"\" required=\"\">\n\t\t\t</div>\n\t\t\t\n\t\t\t<div class=\"relative flex-1\">\n\t\t\t\t<label for=\"lening-").concat(count, "-rente\">Rente</label>\n\t\t\t\t<input type=\"number\" id=\"lening-").concat(count, "-rente\" step=\"0.1\" name=\"rente[]\" placeholder=\"&percnt;\" value=\"\" required=\"\">\n\t\t\t</div>\n\t\t\t\n\t\t\t<div class=\"relative flex-1\">\n\t\t\t\t<label for=\"lening-").concat(count, "-maandtermijn\">Maandtermijn</label>\n\t\t\t\t<input type=\"number\" id=\"lening-").concat(count, "-maandtermijn\" name=\"maandtermijn[]\" placeholder=\"&euro;\" value=\"\" required=\"\">\n\t\t\t</div>\n\n\t\t</div>\n\t");
  loans.append(newLoan);
  iconInput();
  newLoan.querySelector('.remove-loan').addEventListener('click', function (e) {
    removeLoan(e);
  });
};

var calculateSaveLoan = function calculateSaveLoan() {
  var result = saveLoan.querySelector('.result');
  var form = document.forms.save_loan;
  var formData = new FormData(form);
  var data = new URLSearchParams(formData).toString();
  fetch("".concat(rest.restUrl + 'calc/v1/save-loan?' + data + '&_wpnonce=' + rest.restNonce)).then(function (res) {
    return res.json();
  }).then(function (res) {
    log(res);
    saveLoan.querySelector('.result').classList.remove('hidden');
    saveLoan.querySelector('.result .notice').innerHTML = res.notice;
    saveLoan.querySelector('input[name="leenbedrag"]').value = res.amount;
  })["catch"](function (error) {
    return console.error(error);
  });
};

if (null != saveLoan) {
  var add = saveLoan.querySelector('.add-loan');
  var calc = saveLoan.querySelector('.calc-loan'); //if ( 'true' == saveLoan.dataset.calculate && 'true' == saveLoan.dataset.result ) { 

  if (null != add) {
    add.addEventListener('click', function (e) {
      e.preventDefault();
      addLoan(e);
    });
    calc.addEventListener('click', function (e) {
      e.preventDefault();
      calculateSaveLoan();
    });
  } //}

} // Interest


var table = document.querySelectorAll('.acf-interest-block');

var calculateInterest = function calculateInterest(table) {
  var form = document.forms.calculate_interest;
  var formData = new FormData(form);
  var data = new URLSearchParams(formData).toString();
  var product = table.querySelector('[name="product"]');
  var amount = table.querySelector('[name="amount"]');
  var body = table.querySelector('.body');
  fetch("".concat(rest.restUrl + 'calc/v1/interest?' + data + '&_wpnonce=' + rest.restNonce)).then(function (res) {
    return res.json();
  }).then(function (res) {
    console.log(res);

    var _iterator2 = _createForOfIteratorHelper(Object.entries(res).reverse()),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _step2$value = _slicedToArray(_step2.value, 2),
            key = _step2$value[0],
            value = _step2$value[1];

        var row = table.querySelector("[data-amount=\"".concat(key, "\"]"));

        if (key == amount.value) {
          row.classList.remove('hidden');
        } else {
          row.classList.add('hidden');
        }

        row.querySelector('.interest .value').innerHTML = "".concat(value.interest, "%");
        row.querySelector('.term .value').innerHTML = "\u20AC ".concat(value.installment);
        row.querySelector('.duration .value').innerHTML = "".concat(value.duration, " mnd");
        row.querySelector('.price .value').innerHTML = "\u20AC ".concat(value.price);
        row.dataset.bank = "".concat(value.bank);
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  })["catch"](function (error) {
    return console.error(error);
  });
};

var _loop = function _loop(_i2) {
  var select = table[_i2].querySelectorAll('select');

  var product = table[_i2].querySelector('[name="product"]');

  var duration = table[_i2].querySelector('[name="duration"]');

  var monthly = table[_i2].querySelector('[name="monthly"]');

  var body = table[_i2].querySelector('.body');

  for (var x = 0; x < select.length; x++) {
    select[x].addEventListener('change', function (e) {
      try {
        calculateInterest(table[_i2]);
        body.classList.add('loading', 'children:opacity-20');

        if (e.target.name == 'product') {
          if (e.target.value == 'pl') {
            duration.parentNode.classList.remove('hidden');
            monthly.parentNode.classList.add('hidden');
          }

          if (e.target.value == 'dk') {
            duration.parentNode.classList.add('hidden');
            monthly.parentNode.classList.remove('hidden');
          }
        }
      } catch (e) {
        console.log('Error.');
        return false;
      }
    });
  }
};

for (var _i2 = 0; _i2 < table.length; _i2++) {
  _loop(_i2);
}