/* card-link.js */
var handleClick = function handleClick(event, link) {
  var isTextSelected = window.getSelection().toString();

  if (!isTextSelected) {
    link.click();
  }
};

document.addEventListener('DOMContentLoaded', function () {
  var cardLink = document.querySelectorAll('.card-link');

  for (var i = 0; i < cardLink.length; i++) {
    cardLink[i].addEventListener('click', function (e) {
      var link = e.target.closest('.card-link').querySelector('a');
      handleClick(e, link);
    });
  }
});
document.addEventListener('DOMContentLoaded', function () {
  var imageContainer = document.querySelectorAll('.wp-block-cover:has(.card-link)');

  var _loop = function _loop(i) {
    var zoomImage = imageContainer[i].querySelector('img');
    imageContainer[i].addEventListener('mouseover', function () {//zoomImage.style.transform = 'scale(1.1)';
    });
    imageContainer[i].addEventListener('mouseout', function () {//zoomImage.style.transform = 'scale(1)';
      //zoomImage.style.transformOrigin = 'center center';
    });
    imageContainer[i].addEventListener('mousemove', function (e) {
      var rect = imageContainer[i].getBoundingClientRect();
      var offsetX = e.clientX - rect.left;
      var offsetY = e.clientY - rect.top;
      var originX = offsetX / rect.width * 100;
      var originY = offsetY / rect.height * 100;
      zoomImage.style.transformOrigin = "".concat(originX, "% ").concat(originY, "%");
    });
  };

  for (var i = 0; i < imageContainer.length; i++) {
    _loop(i);
  }
});