/* icon-input.js */
window.iconInput = function () {
  var input = document.getElementsByTagName('input');

  for (var i = 0; i < input.length; i++) {
    if (['€', '%', 'mnd'].includes(input[i].placeholder)) {
      var elem = input[i];
      var wrapper = document.createElement('div');
      wrapper.classList.add('input-wrap', 'relative');

      if ('€' === elem.placeholder) {
        wrapper.classList.add('euro');
      }

      if ('%' === elem.placeholder) {
        wrapper.classList.add('percent');
      }

      if ('mnd' === elem.placeholder) {
        wrapper.classList.add('month');
      }

      elem.parentNode.insertBefore(wrapper, elem);
      var icon = document.createElement('span');
      icon.classList.add('icon-input');

      if ('€' === elem.placeholder) {
        icon.classList.add('euro');
        icon.innerHTML += '€';
      }

      if ('%' === elem.placeholder) {
        icon.classList.add('percent');
        icon.innerHTML += '%';
      }

      if ('mnd' === elem.placeholder) {
        icon.classList.add('month');
        icon.innerHTML += 'mnd';
      }

      elem.placeholder = '';
      wrapper.appendChild(icon);
      wrapper.appendChild(elem);
    }
  }
};

window.addEventListener('load', function () {//iconInput();
});