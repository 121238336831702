/* validation.js */
// Validation: amount
var amount = document.querySelectorAll('.calculate-amount');

var createAmountValidation = function createAmountValidation(e) {
  var min = document.createElement('span');
  var max = document.createElement('span');
  min.classList.add('validation_message', 'validation_message_custom', 'amount', 'min', 'hidden');
  max.classList.add('validation_message', 'validation_message_custom', 'amount', 'max', 'hidden');
  min.innerHTML += "Kies een leenbedrag van minimaal &euro; ".concat(Number(e.querySelector('input').min).toLocaleString('nl'));
  max.innerHTML += "Kies een leenbedrag van maximaal &euro; ".concat(Number(e.querySelector('input').max).toLocaleString('nl'));
  e.closest('.calculate-amount').appendChild(min);
  e.closest('.calculate-amount').appendChild(max);
}; // Min/max


window.checkMinMax = function (e) {
  var elem = e;
  var min = elem.closest('.calculate-amount').querySelector('.validation_message.validation_message_custom.amount.min');
  var max = elem.closest('.calculate-amount').querySelector('.validation_message.validation_message_custom.amount.max'); // If the value is less than the min/max then stop

  if (Number(elem.value) > elem.min && Number(elem.value) < elem.max) {
    min.classList.add('hidden');
    max.classList.add('hidden');
    return;
  } // Snap the value to the min


  if (Number(elem.value) < elem.min) {
    elem.value = elem.min;
    min.classList.remove('hidden');
    min.classList.add('block');
    max.classList.remove('block');
    max.classList.add('hidden');
  } // Snap the value to the max


  if (Number(elem.value) > elem.max) {
    elem.value = elem.max;
    max.classList.remove('hidden');
    max.classList.add('block');
    min.classList.remove('block');
    min.classList.add('hidden');
  }

  if (elem.charCode >= 48 && elem.charCode <= 57) {
    elem.value = 50000;
  }
};

var _loop = function _loop(i) {
  window.addEventListener('load', function (e) {
    createAmountValidation(amount[i]);
    checkMinMax(amount[i].querySelector('input'));
  });
  amount[i].addEventListener('blur', function (e) {
    checkMinMax(e.target);
  });
};

for (var i = 0; i < amount.length; i++) {
  _loop(i);
}