/* scripts.js */
require('./validation.js'); //require( './marquee.js' );


require('./card-link.js');

require('./icon-input.js');

require('./tools.js'); //require( './upload.js' );


require('./offerte.js');

require('./zip.js');