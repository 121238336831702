/* offerte.js */
// Calculate
var form = document.querySelector('.gform_wrapper ');

window.calculateQuote = function () {
  var summary = document.querySelector('.is-calc-summary');
  var data = new URLSearchParams();
  data.append('loan_amount', form.querySelector('.is-custom-gf-amount input').value);
  data.append('loan_product', form.querySelector('.is-custom-gf-product input:checked').value);
  data.append('loan_duration', form.querySelector('.is-custom-gf-duration select').value);
  data.append('loan_monthly', form.querySelector('.is-custom-gf-monthly select').value);
  data.append('loan_goal', form.querySelector('.is-custom-gf-goal select').value);
  data.append('loan_homeowner', form.querySelector('.is-custom-gf-homeowner input:checked').value);
  console.log(data.toString());
  fetch("".concat(rest.restUrl + 'calc/v1/quote?' + data.toString() + '&_wpnonce=' + rest.restNonce)).then(function (res) {
    return res.json();
  }).then(function (res) {
    console.log(res);
    summary.querySelector('.amount .value').innerHTML = "\u20AC ".concat(res.amount);
    summary.querySelector('.interest .value').innerHTML = "".concat(res.interest, "%");
    summary.querySelector('.installment .value').innerHTML = "\u20AC ".concat(res.installment);
    summary.querySelector('.duration .value').innerHTML = "".concat(res.duration, " maanden");
    summary.querySelector('.price .value').innerHTML = "\u20AC ".concat(res.price);
  })["catch"](function (error) {
    return console.error(error);
  });
};

var updateDuration = function updateDuration(goal) {
  var array = ['132', '144', '156', '168', '180'];
  var select = document.querySelector('.is-custom-gf-duration select');
  var selectValue = select.value;
  var notice = select.closest('.gfield.has-custom-gfield_description');

  if (-1 !== ['badkamer', 'dakkapel', 'isoleren-woning', 'keuken', 'kozijnen', 'tuin', 'verbouwing', 'verduurzamen', 'warmtepomp', 'zonnepanelen'].indexOf(goal) && document.querySelector('.is-custom-gf-homeowner input[type="radio"]:checked').value == 1) {
    array.forEach(function (key) {
      var option = document.querySelector(".is-custom-gf-duration option[value=\"".concat(key, "\"]"));

      if (!option) {
        var newOption = new Option("".concat(key, " maanden"), key);
        select.add(newOption, undefined);
        notice.classList.add('show-custom-gfield_description');
      }
    });
  } else {
    array.forEach(function (key) {
      var option = document.querySelector(".is-custom-gf-duration option[value=\"".concat(key, "\"]"));

      if (null != option) {
        option.remove();
      }
    });

    if (array.includes(selectValue)) {
      select.value = 120;
    }

    notice.classList.remove('show-custom-gfield_description');
  }
};

if (null != form) {
  (function () {
    var amount = form.querySelector('.is-custom-gf-amount input');
    var product = form.querySelectorAll('.is-custom-gf-product input');
    var goal = form.querySelector('.is-custom-gf-goal select');
    var homeowner = form.querySelectorAll('.is-custom-gf-homeowner input');
    var duration = form.querySelector('.is-custom-gf-duration select');
    var monthly = form.querySelector('.is-custom-gf-monthly select');
    window.addEventListener('load', function () {
      updateDuration(goal.value);
      calculateQuote();
    });
    amount.addEventListener('change', function (e) {
      try {
        checkMinMax(e.target);
        calculateQuote();
      } catch (e) {
        console.log('Error with amount.');
        return false;
      }
    });

    for (var i = 0; i < product.length; i++) {
      product[i].addEventListener('change', function (e) {
        try {
          calculateQuote();
        } catch (e) {
          console.log('Error with product.');
          return false;
        }
      });
    }

    goal.addEventListener('change', function (e) {
      try {
        updateDuration(e.target.value);
        calculateQuote();
      } catch (e) {
        console.log('Error with goal.');
        return false;
      }
    });

    for (var _i = 0; _i < homeowner.length; _i++) {
      homeowner[_i].addEventListener('change', function (e) {
        try {
          updateDuration(goal.value);
          calculateQuote();
        } catch (e) {
          console.log('Error with homeowner.');
          return false;
        }
      });
    }

    duration.addEventListener('change', function (e) {
      try {
        calculateQuote();
      } catch (e) {
        console.log('Error with duration.');
        return false;
      }
    });
    monthly.addEventListener('change', function (e) {
      try {
        calculateQuote();
      } catch (e) {
        console.log('Error with monthly.');
        return false;
      }
    });
  })();
} // Initials

/*const voorletters = document.querySelectorAll( '.is-custom-gf-name input' );

const updateInitials = (e) => {

	// Var
	const elem = e;
	const {value} = elem.target;
	let newValue = '';

	for ( let i=0; i < value.length; i++ ) {

		const chars = value.charAt(i).toUpperCase();

		if ( ( 'A' <= chars && 'Z' >= chars ) ) {

			newValue += `${chars}.`;

		}

	}

	elem.target.value = newValue;

};

for ( let i=0; i < voorletters.length; i++ ) {

	voorletters[i].addEventListener( 'change', (e) => {

		updateInitials(e);

	} );

}*/
// Age


var age = document.querySelectorAll('.is-custom-gf-age');

var createAgeValidation = function createAgeValidation(e) {
  var msg = document.createElement('span');
  msg.classList.add('validation_message', 'validation_message_custom', 'age', 'hidden');
  msg.innerHTML += 'Je moet 21 jaar of ouder zijn om een lening aan te vragen!';
  e.appendChild(msg);
};

window.addEventListener('load', function () {
  for (var i = 0; i < age.length; i++) {
    createAgeValidation(age[i]);
  }
});

var initAgeCheck = function initAgeCheck(e) {
  var today = new Date();
  var nowyear = today.getFullYear();
  var nowmonth = today.getMonth();
  var nowday = today.getDate();
  var value = e.target.value;
  var arr = value.split('-');
  var validation = e.target.closest('.is-custom-gf-age').querySelector('.validation_message.validation_message_custom.age');

  if ('' !== value) {
    if (31 >= arr[0] && 12 >= arr[1] && nowyear > arr[2]) {
      e.target.closest('.gfield').classList.remove('is-custom-gf-age--invalid');
      e.target.closest('.gfield').classList.add('is-custom-gf-age--valid');
    } else {
      e.target.closest('.gfield').classList.remove('is-custom-gf-age--valid');
      e.target.closest('.gfield').classList.add('is-custom-gf-age--invalid');
    }
  }

  if (true == e.target.closest('.gfield').classList.contains('is-custom-gf-age') && true == e.target.closest('.gfield').classList.contains('is-custom-gf-age--valid')) {
    var birthyear = arr[2];
    var birthmonth = arr[1];
    var birthday = arr[0];

    var _age = nowyear - birthyear;

    var ageMonth = nowmonth - birthmonth;
    var ageDay = nowday - birthday;

    if (0 > ageMonth || 0 == ageMonth && 0 > ageDay) {
      _age = parseInt(_age);
    }

    if (21 == _age && 0 > ageMonth && 0 > ageDay || 21 > _age) {
      //e.target.closest( '.gfield' ).classList.add( 'gfield_error', 'gfield_error_custom' );
      validation.classList.remove('hidden');
      return false;
    } else {
      //e.target.closest( '.gfield' ).classList.remove( 'gfield_error', 'gfield_error_custom' );
      validation.classList.add('hidden');
      return false;
    }
  }
};

for (var i = 0; i < age.length; i++) {
  var input = age[i].querySelector('input');
  input.addEventListener('blur', function (e) {
    initAgeCheck(e);
  });
} // Month


var month = document.querySelectorAll('.gfield_date_dropdown_month select');

var replaceMonth = function replaceMonth(e) {
  e.options[1].text = 'Januari';
  e.options[2].text = 'Februari';
  e.options[3].text = 'Maart';
  e.options[4].text = 'April';
  e.options[5].text = 'Mei';
  e.options[6].text = 'Juni';
  e.options[7].text = 'Juli';
  e.options[8].text = 'Augustus';
  e.options[9].text = 'September';
  e.options[10].text = 'Oktober';
  e.options[11].text = 'November';
  e.options[12].text = 'December';
};

window.addEventListener('load', function () {
  for (var _i2 = 0; _i2 < month.length; _i2++) {
    replaceMonth(month[_i2]);
  }
});
window.addEventListener('load', function () {
  var offerteBody = document.querySelector('.page-template-offerte');
  /*
  	if ( offerteBody ) {
  
  		// Algemeen
  		document.getElementById( 'input_1_1' ).value = '50000';
  		document.getElementById( 'input_1_3' ).value = 'auto';
  		document.getElementById( 'input_1_70' ).value = '7,0%';
  		document.getElementById( 'input_1_71' ).value = '120';
  		document.getElementById( 'input_1_72' ).value = '250';
  
  		// Gegevens aanvrager
  		document.getElementById( 'choice_1_7_0' ).checked = true;
  		document.getElementById( 'input_1_8_3' ).value = 'ABC';
  		document.getElementById( 'input_1_8_4' ).value = 'de';
  		document.getElementById( 'input_1_8_6' ).value = 'FGH';
  		document.getElementById( 'input_1_9' ).value = '31-01-1990';
  		//document.getElementById( 'input_1_10' ).value = 'NED';
  		document.getElementById( 'input_1_11' ).value = '0318-452452';
  		document.getElementById( 'input_1_12' ).value = 'derk@a-krediet.nl';
  		document.getElementById( 'input_1_13' ).value = '6721 JS';
  		document.getElementById( 'input_1_14' ).value = '121';
  		document.getElementById( 'input_1_15' ).value = 'a';
  		document.getElementById( 'input_1_16' ).value = 'Edeseweg';
  		document.getElementById( 'input_1_17' ).value = 'Bennekom';
  		document.getElementById( 'choice_1_19_0' ).checked = true;
  		document.getElementById( 'choice_1_20_0' ).checked = true;
  		document.getElementById( 'choice_1_21_0' ).checked = true;
  		document.getElementById( 'input_1_22_2' ).value = '1'; // dd
  		document.getElementById( 'input_1_22_1' ).value = '2'; // mm
  		document.getElementById( 'input_1_22_3' ).value = '2010'; // yyyy
  		document.getElementById( 'input_1_23' ).value = '123';
  		document.getElementById( 'input_1_24' ).value = '345';
  		document.getElementById( 'input_1_25' ).value = '567';
  		document.getElementById( 'input_1_26' ).value = '789';
  
  		(function($) {
  
  			$( '#input_1_13' ).change();
  			$( '#choice_1_20_0' ).change();
  			$( '#choice_1_21_0' ).change();
  
  		})(jQuery);
  
  		// Woonsituatie
  		document.getElementById( 'label_1_28_0' ).checked = true;
  		document.getElementById( 'input_1_29_2' ).value = '1'; // dd
  		document.getElementById( 'input_1_29_1' ).value = '2'; // mm
  		document.getElementById( 'input_1_29_3' ).value = '2022'; // yyyy
  		document.getElementById( 'input_1_30' ).value = '123';
  
  		// Inkomen aanvrager
  		document.getElementById( 'input_1_34' ).value = '1';
  		document.getElementById( 'input_1_35_2' ).value = '1'; // dd
  		document.getElementById( 'input_1_35_1' ).value = '12'; // mm
  		document.getElementById( 'input_1_35_3' ).value = '2020'; // yyyy
  		document.getElementById( 'input_1_36' ).value = '1234';
  		document.getElementById( 'input_1_37' ).value = '5678';
  		document.getElementById( 'input_1_38' ).value = 'maand';
  		document.getElementById( 'input_1_39' ).value = '22';
  
  		(function($) {
  
  			$( '#input_1_34' ).change();
  
  		})(jQuery);
  
  		// Gegevens partner
  		document.getElementById( 'choice_1_46_1' ).checked = true;
  		document.getElementById( 'input_1_47_3' ).value = 'STU';
  		document.getElementById( 'input_1_47_4' ).value = 'vw';
  		document.getElementById( 'input_1_47_6' ).value = 'XYZ';
  		document.getElementById( 'input_1_48' ).value = '01-12-1990';
  		//document.getElementById( 'input_1_49' ).value = 'NED';
  
  		// Inkomen partner
  		document.getElementById( 'input_1_51' ).value = '1';
  		document.getElementById( 'input_1_52_2' ).value = '31'; // dd
  		document.getElementById( 'input_1_52_1' ).value = '1'; // mm
  		document.getElementById( 'input_1_52_3' ).value = '2020'; // yyyy
  		document.getElementById( 'input_1_53' ).value = '1234';
  		document.getElementById( 'input_1_54' ).value = '5678';
  		document.getElementById( 'input_1_55' ).value = 'maand';
  		document.getElementById( 'input_1_56' ).value = '13';
  
  		(function($) {
  
  			$( '#input_1_63' ).change();
  
  		})(jQuery);
  
  		// Auto
  		document.getElementById( 'choice_1_64_1' ).checked = true;
  		//document.querySelectorAll( 'select[name^="input_164"]' )[0].value = '01';
  		//document.querySelectorAll( 'input[name^="input_164"]' )[0].value = '123';
  
  		(function($) {
  
  			//$( '#choice_1_64_1' ).change();
  
  		})(jQuery);
  
  		// Lopende leningen
  		document.getElementById( 'choice_1_66_1' ).checked = true;
  		//document.querySelectorAll( 'select[name^="input_149"]' )[0].value = 'ABN';
  		//document.querySelectorAll( 'input[name^="input_149"]' )[0].value = '456';
  
  		(function($) {
  
  			//$( '#choice_1_66_1' ).change();
  
  		})(jQuery);
  
  		// Opmerkingen
  		document.getElementById( 'input_1_68' ).value = 'Test!';
  
  		// Nieuwsbrief
  		document.getElementById( 'choice_1_78_1' ).checked = true;
  
  	}
  */
});